<template>
    <v-card flat>
        <v-card-text>
        <v-card
            v-for="(field, index) in this.RisksCC"
            :key="index"
            class="mb-3"
            >
            <v-card-title>
                {{field.Risks}}
            </v-card-title>
            <v-card>
                  <v-card-title>
                    People
                </v-card-title>
            <v-card-text>
                <v-data-table
                :headers="headers"
                :items="returnIndicators('PEOPLE')"
                class="elevation-1"
                item-key=".key"
                single-expand
                show-expand
                :hide-default-footer="true"
                >
                <template v-slot:item.Control="{ item }">
                    <td v-if="item.ControlList.length > 2">
                        <div style="width: 500px">
                            {{item.Control}}:
                        </div>
                        <div 
                        v-for="(ControlList, index) in item.ControlList"
                        :key="index"
                        >
                            <v-list-item class="pb-1">• {{ControlList}}</v-list-item>
                        </div>
                    </td>

                    <td v-else>
                        <div class="pa-4 py-8">
                            {{item.Control}}
                        </div>
                    </td>
                </template>

             

                <template v-slot:expanded-item="{item}">
                    <td :colspan="12">
                        <v-row>
                            <v-col lg="12">
                                 <v-data-table
                                    :headers="headers2"
                                    :items="[item]"
                                    :hide-default-footer="true"
                                >
                                       <template v-slot:item.Score="props">
                                        <v-edit-dialog
                                            large
                                            :return-value.sync="props.item.Score"
                                            @save="saveEditDialog(props.item)"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close"
                                            >
                                            {{ props.item.Score }}
                                            <template v-slot:input
                                            >
                                               <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.Score"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                </v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:item.StatusOfControl="props">
                                        <v-edit-dialog
                                            style="width: 350px"
                                            large
                                            :return-value.sync="props.item.StatusOfControl"
                                            @save="saveEditDialog(props.item)"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close"
                                            >
                                            {{ props.item.StatusOfControl }}
                                            <template v-slot:input
                                            >
                                                <v-select
                                                    :items="item.Metric"
                                                    class="inputBox"
                                                    item-text="Metric"
                                                    item-value='Metric'
                                                    v-model="props.item.StatusOfControl"
                                                    label="Edit"
                                                    >
                                                </v-select>
                                            </template>
                                        </v-edit-dialog>
                </template>

                    <template v-slot:item.Metric="{ item }">
                    <td>
                        <div 
                        v-for="(Metric, index) in item.Metric"
                        :key="index"
                        >
                            <v-list-item>{{Metric}}</v-list-item>
                        </div>
                    </td>
                    
                </template>
                 <template v-slot:item.Scoring="{ item }">
                    <td>
                        <div 
                        v-for="(Scoring, index) in item.Scoring"
                        :key="index"
                        >
                            <v-list-item>{{Scoring}}</v-list-item>
                        </div>
                    </td>
                </template>
                
                    </v-data-table>
                            </v-col>
                        </v-row>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
            </v-card>
                 <v-card>
                  <v-card-title>
                    Process and Technology
                </v-card-title>
            <v-card-text>
                <v-data-table
                :headers="headers"
                :items="returnIndicators('PROCESS & TECHNOLOGY')"
                class="elevation-1"
                item-key=".key"
                single-expand
                show-expand
                :footer-props="{'items-per-page-options':[2,15, 30, 50, 100, -1]}"
                :options="options"
                :hide-default-footer="true"
                >
                <template v-slot:item.Control="{item}">
                    <td>
                        <div style="width: 350px" class="pa-4 py-8">
                            {{item.Control}}
                        </div>
                        
                    </td>
                </template>
                <template v-slot:expanded-item="{item}">
                    <td :colspan="12">
                        <v-row>
                            <v-col lg="12">
                                 <v-data-table
                                    :headers="headers2"
                                    :items="[item]"
                                    :hide-default-footer="true"
                                >
                                        <template v-slot:item.Score="props">
                                        <v-edit-dialog
                                            large
                                            :return-value.sync="props.item.Score"
                                            @save="saveEditDialog(props.item)"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close"
                                            >
                                            {{ props.item.Score }}
                                            <template v-slot:input
                                            >
                                               <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.Score"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                </v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:item.StatusOfControl v-if="item.Control === 'KYC- Percentage of customers that are long standing (>5 years) relationships (excluding dormant) against all customers.'">
                                        <v-data-table
                                            :headers="headers3"
                                            :items="[item]"
                                            :hide-default-footer="true"
                                            >
                                            <template v-slot:item.TotalNoOfCustomer="props">
                                                <v-edit-dialog
                                                large
                                                :return-value.sync="props.item.TotalNoOfCustomer"
                                                @save="saveEditDialog(props.item)"
                                                @cancel="cancel"
                                                @open="open"
                                                @close="close"
                                                >
                                            {{ props.item.TotalNoOfCustomer }}
                                            <template v-slot:input
                                            >
                                                 <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.TotalNoOfCustomer"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                    </v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                        <template v-slot:item.NoOfCustomer="props">
                                                <v-edit-dialog
                                                large
                                                :return-value.sync="props.item.NoOfCustomer"
                                                @save="saveEditDialog(props.item)"
                                                @cancel="cancel"
                                                @open="open"
                                                @close="close"
                                                >
                                            {{ props.item.NoOfCustomer }}
                                            <template v-slot:input
                                            >
                                                 <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.NoOfCustomer"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                    </v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:item.Percentage="props">
                                             {{returnPercentage(props.item)}} 
                                    </template>
                                        </v-data-table>
                                    </template>
                                       <template v-slot:item.StatusOfControl="props" v-else>
                                        <v-edit-dialog
                                            style="width: 350px"
                                            large
                                            :return-value.sync="props.item.StatusOfControl"
                                            @save="saveEditDialog(props.item)"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close"
                                            >
                                            {{ props.item.StatusOfControl }}
                                            <template v-slot:input
                                            >
                                                <v-select
                                                    :items="item.Metric"
                                                    class="inputBox"
                                                    item-text="Metric"
                                                    item-value='Metric'
                                                    v-model="props.item.StatusOfControl"
                                                    label="Edit"
                                                    >
                                                </v-select>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                   
                    <template v-slot:item.Metric="{ item }">
                    <td>
                        <div 
                        v-for="(Metric, index) in item.Metric"
                        :key="index"
                        >
                            <v-list-item>{{Metric}}</v-list-item>
                        </div>
                    </td>
                </template>
                   <template v-slot:item.Scoring="{ item }">
                    <td>
                        <div 
                        v-for="(Scoring, index) in item.Scoring"
                        :key="index"
                        >
                            <v-list-item>{{Scoring}}</v-list-item>
                        </div>
                    </td>
                </template>
                    </v-data-table>
                            </v-col>
                        </v-row>
                    </td>
                </template>

              
                </v-data-table>
                        <div class="d-flex justify-end pa-4 py-1 mr-3 mt-3">
                        
                        <span class="mr-3"><strong>Control Total Score  - </strong></span>
                        <span>{{returnTotal}}</span>
                    </div>
                    <div class="d-flex justify-end pa-4 py-1 mr-3 mb-2">
                        <span class="mr-3"><strong>Control Rating  - </strong></span>
                        <span>{{returnRating()}}</span>
                    </div>
                
            </v-card-text>
            </v-card>
        </v-card>
        </v-card-text>
        
    </v-card>  

</template>

<script>
export default {
    data(){
        return{
            options: {
                itemsPerPage: 100
            },
            headers: [
                {
                    text: 'Control',
                    align: 'start',
                    value: 'Control',
                },
                { text: 'Rationale', value: 'Rationale' },
            ],
            headers2: [
                 {
                    text: 'Metric',
                    align: 'start',
                    value: 'Metric',
                },
                { text: 'Scoring', value: 'Scoring' },
                { text: 'Status of Control', value: 'StatusOfControl' },
                { text: 'Score', value: 'Score' },

            ],
            headers3: [
                 {
                    text: 'Total No. of Customer',
                    align: 'start',
                    value: 'TotalNoOfCustomer',
                },
                {text: 'Customer >5 Yrs', value: 'NoOfCustomer'},
                {text: 'Percentage', value: 'Percentage'},
            ]
        }
    },
     firestore(){
      return {
        ControlIndicatorsCC: this.$db.collection('ControlIndicators-CC'),
        IRAReportCC: this.$db.collection('IRAReport-CC').where("IRACoverageId", "==", this.$route.params.id),
        RisksCC: this.$db.collection('Risks-CC')
      }
     },

     updated(){
         this.$emit('update', this.returnRating());
     },
   
     methods: {
        close(){},
        cancel(){},
        open(){},
          returnRating(){
             let total = this.returnTotal
          if(total <= 5){
            return 'WEAK'
          } else if(total < 29.99){
            return 'ACCEPTABLE'
          } else if(total >= 30) {
            return 'STRONG'
          }
          
         },
        returnPercentage(props){
            if(props.NoOfCustomer == undefined || props.TotalNoOfCustomer == undefined){
                return '-'
            } else {
                 let num 
            num = (props.NoOfCustomer/props.TotalNoOfCustomer)
            return num.toFixed(2)+'%'
            }
           
        },
        saveEditDialog(props){
            let report = {...props}
            let key = props['.key']

      

            report.Modified = this.$firebase.firestore.FieldValue.serverTimestamp()
            console.log(report, key)
            this.$db.collection('IRAReport-CC').doc(key).set(report)
            .then(async()=>{
                await this.$store.dispatch('logs/createNotifs', {collection:'IRAReport-CC',documentKey:key,module: 'IRA',action: 'Updated an IRA Control Category Report'})  
                this.$dialog.notify.success(`Success Updated IRA Control Category Report`, {
                    position: 'bottom-right',
                    timeout: 3000
                })    
            })
        },

         returnIndicators(section){
             let filter = this.IRAReportCC.filter(e=>{
                 return [section] == e.Section
             })
             console.log(filter)
             return filter
         },
     },
     computed:{
         returnTotal(){
            let report = this.IRAReportCC
            let arr = []
             report.forEach(e=>{
                 arr.push(e.Score)
             })
             let result = (arr.reduce((a, b) => a + b, 0))
              return result
         },
       
     }
}
</script>
<style  scoped>
.v-dialog:not(.v-dialog--fullscreen) {
    bottom: 0 !important;
    right: 0 !important;
    position: absolute !important;
}
</style>