<template>
 <v-card flat>
    <v-card-text>
        <v-card
            v-for="(field, index) in returnRisks()"
            :key="index"
            class="mb-3"
            >
            <v-card-title>
                {{field.Risks}}
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="returnIndicators(field)"
                class="elevation-1"
                single-expand
                show-expand
                item-key=".key"
                :hide-default-footer="true"
                >
                  <template v-slot:item.Average="props">
                                          <td>
                                            {{props.item.Average.toFixed(2)}}
                                          </td>
                                        </template>
                <template v-slot:item.Thresholds="{ item }">
                    <td v-if="item.ThresholdType === 'PHP'">
                        <div style="width: 200px">
                            <v-list-item>>5M = HIGH</v-list-item>
                            <v-list-item>>1M-5M = HIGH</v-list-item>
                            <v-list-item>>500K-1M = MEDIUM</v-list-item>
                            <v-list-item>>100K-500K = MEDIUM</v-list-item>
                            <v-list-item>>100k-200K = LOW</v-list-item>
                        </div>
                    </td>

                    <td v-else>
                        <div style="width: 200px">
                            <v-list-item>>95% =HIGH</v-list-item>
                            <v-list-item>>65%-95% =HIGH</v-list-item>
                            <v-list-item>>35%-65% =MEDIUM</v-list-item>
                            <v-list-item>>5%-35% = MEDIUM</v-list-item>
                            <v-list-item>5% = LOW"</v-list-item>
                        </div>
                    </td>
                </template>

                <template v-slot:item.RiskScoringMetircs>
                    <td>
                        <div style="width: 200px">
                            <v-list-item>>HIGH = 5</v-list-item>
                            <v-list-item>>MEDIUM = 3-4</v-list-item>
                            <v-list-item>>500K-1M = MEDIUM</v-list-item>
                            <v-list-item>>LOW = 1-2</v-list-item>
                            <v-list-item>>NIL/NA = 0"</v-list-item>
                        </div>
                    </td>
                </template>

        
                <template slot="footer">
                    <div class="d-flex justify-end pa-4 py-1 mr-3">
                        
                        <span class="mr-3"><strong>Total Risk Score ({{field.Risks}}) - </strong></span>
                        <span>{{returnAve(field).toFixed(2)}}</span>
                    </div>
                    <div class="d-flex justify-end pa-4 py-1 mr-3 mb-2">
                        <span class="mr-3"><strong>ML/TF Risk Rating ({{field.Risks}}) - </strong></span>
                        <span>{{returnRating(returnAve(field))}}</span>
                    </div>
                </template>
                            
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="12">
                        <v-row>
                            <v-col lg="12">
                                <v-card
                                    class="mb-3"
                                     v-for="(field, index) in returnItems(item)"
                                    :key="index"
                                    >
                                    <v-card-title>
                                        {{ field.Department }}
                                    </v-card-title>
                                    <v-data-table
                                        :headers="returnHeaders(item)"
                                        :items="[field]"
                                        :hide-default-footer="true"
                                        >

                                        <template v-slot:item.Percentage="props">
                                          <td>
                                            {{displayPercentage(props.item)}}
                                          </td>
                                        </template>

                                       

                                         <template v-slot:item.Vulnerability="props">
                                          <td>
                                            {{displayVulnerability(props.item)}}
                                          </td>
                                        </template>

                                         <template v-slot:item.RiskScore="{ item }">
                                          <td>
                                            {{item.Likelihood}} - {{returnMetric(item)}}
                                          </td>
                                        </template>

                                        <template v-slot:item.ImpactScore="props">
                                          <v-edit-dialog
                                             
                                              style="width: 150px;"
                                              @cancel="cancel"
                                              @open="open"
                                              @close="close"
                                                >
                                                {{ props.item.ImpactScore }}
                                                <template v-slot:input>
                                                    <!-- <v-text-field
                                                        min="0"
                                                        max="5"
                                                        class="inputBox"
                                                        v-model.number="props.item.ImpactScore"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                    </v-text-field> -->
                                                    <v-slider
                                                      class="mt-15"
                                                      v-model="slider"
                                                      min="0"
                                                      max="5"
                                                      v-model.number="props.item.ImpactScore"
                                                      thumb-label="always"
                                                      @change="saveEditDialog(props.item)"
                                                    ></v-slider>
                                                </template>
                                          </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.AverageVolumeAmount="props">
                                          <v-edit-dialog
                                              :return-value.sync="props.item.AverageVolumeAmount"
                                              large
                                              @save="saveEditDialog(props.item)"
                                              @cancel="cancel"
                                              @open="open"
                                              @close="close"
                                                >
                                                {{ props.item.AverageVolumeAmount }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.AverageVolumeAmount"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                    </v-text-field>
                                                </template>
                                          </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.TotalFinancialImpact="props">
                                          <v-edit-dialog
                                              :return-value.sync="props.item.TotalFinancialImpact"
                                              large
                                              @save="saveEditDialog(props.item)"
                                              @cancel="cancel"
                                              @open="open"
                                              @close="close"
                                                >
                                                {{ props.item.TotalFinancialImpact }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.TotalFinancialImpact"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                    </v-text-field>
                                                </template>
                                          </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.Likelihood="props">
                                          <v-edit-dialog
                                         
                                              @open="open"
                                              @close="close"
                                              @cancel="cancel"
                                              style="width: 150px;"
                                                >
                                                <td v-if="props.item.IsLikelihoodNull == true">N/A</td>
                                                <td>{{ props.item.Likelihood }}</td>
                                                <template v-slot:input>
                                                    <!-- <v-text-field
                                                        class="inputBox"
                                                        v-model.number="props.item.Likelihood"
                                                        label="Edit"
                                                        single-line
                                                        type="number"
                                                        counter
                                                        >
                                                    </v-text-field> -->
                                                    <v-slider
                                                      v-if="props.item.IsLikelihoodNull == false"
                                                      class="mt-15"
                                                      @change="saveEditDialog(props.item)"
                                                    
                                                      min="0"
                                                      max="5"
                                                      v-model="props.item.Likelihood"
                                                      thumb-label="always"
                                                    ></v-slider>
                                                    <v-checkbox v-model="props.item.IsLikelihoodNull"
                                                    @change="saveEditDialogLikelihood(props.item)"
                                                    >
                                                      <template v-slot:label>
                                                        <div>N/A</div>
                                                      </template>
                                                    </v-checkbox>
                                                </template>
                                            </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.Dividend="props">
                                          <v-edit-dialog
                                            :return-value.sync="props.item.Dividend"
                                              large
                                              @save="saveEditDialog(props.item)"
                                              @cancel="cancel"
                                              @open="open"
                                              @close="close"
                                            >
                                            {{ props.item.Dividend }}
                                            <template v-slot:input>
                                              <v-text-field
                                                class="inputBox"
                                                v-model.number="props.item.Dividend"
                                                label="Edit"
                                                single-line
                                                type="number"
                                                counter
                                              ></v-text-field>
                                            </template>
                                          </v-edit-dialog>
                                        </template>

                                        <template v-slot:item.Divisor="props">
                                          <v-edit-dialog
                                             :return-value.sync="props.item.Divisor"
                                              large
                                              @save="saveEditDialog(props.item)"
                                              @cancel="cancel"
                                              @open="open"
                                              @close="close"
                                                >
                                                {{ props.item.Divisor }}
                                                <template v-slot:input>
                                                    <v-text-field
                                                        v-model.number="props.item.Divisor"
                                                        type="number"
                                                        label="Edit"
                                                        single-line
                                                        counter
                                                        >
                                                    </v-text-field>
                                            </template>
                                          </v-edit-dialog>
                                        </template>
                                      </v-data-table>
                                      </v-card> 
                                  </v-col>
                                </v-row>
                              </td>
                            </template>
                          </v-data-table>

                          </v-card>
                           <div class="d-flex justify-end pa-4 py-1 mr-3">
                            <span class="mr-3"><strong>Total Risk Score - </strong></span>
                            <span>{{returnTotalRating()}} </span>
                          </div>
                        </v-card-text>
                    </v-card>
</template>

<script>
export default {
    data(){
        return {
        headers: [
        {
          text: 'Indicator',
          align: 'start',
          value: 'Indicator',
        },
        { text: 'Rationale', value: 'Rationale' },
        { text: 'Thresholds', value: 'Thresholds'},
        { text: 'Risk Scoring Metrics', value: 'RiskScoringMetircs' },
        { text: 'Average', value: 'Average'},
        ],
        checkbox: false
        }
    },
    firestore(){
      return {
        RiskIndicatorsIR: this.$db.collection('RiskIndicators-IR'),
        IRAReportIR: this.$db.collection('IRAReport-IR').where("IRACoverageId", "==", this.$route.params.id),
        RisksIR: this.$db.collection('Risks-IR')
      }
    },
     updated(){
         this.$emit('update', this.returnTotalRating());
     },
    methods:{
        close(){},
        cancel(){},
        open(){},
        returnMetric(item){
          if(item.Likelihood === 0){
            return "NIL/NA"
          } else if(item.Likelihood === null){
            return "N/A"
          } else if(item.Likelihood === 5){
            return "High"
          } else if(item.Likelihood <= 2){
            return "Low"
          } else if(item.Likelihood >= 3){
            return "Medium"
          } 
        },
        averagePerIndicator(item){
          let arr = []
          let i
          let nullCount = 0
          let newArrayLength
          let filter = this.IRAReportIR.filter(e=>{
            return e.Indicator == item.Indicator
          })
         
          filter.forEach(e=>{
            arr.push(e.Likelihood)
          })
          for(i=0; i < arr.length; i++){
          if(arr[i] === null){
            nullCount++
          }
          newArrayLength = arr.length - nullCount
        }
         let result = (arr.reduce((a, b) => a + b, 0))/newArrayLength
         return result
        },

        saveEditDialog(props){
            let report = {...props}
            let key = props['.key']

            report.Modified = this.$firebase.firestore.FieldValue.serverTimestamp()
            this.$db.collection('IRAReport-IR').doc(key).set(report)
            .then(async()=>{
                await this.$store.dispatch('logs/createNotifs', {collection:'IRAReport-IR',documentKey:key,module: 'IRA',action: 'Updated a IRA Inherent Risk Report'})  
                this.$dialog.notify.success(`Success Updated IRA Inherent Risk Report`, {
                    position: 'bottom-right',
                    timeout: 3000
                })    
            })
        },

        saveEditDialogLikelihood(props){
           let report = {...props}
            let key = props['.key']
            report.Likelihood = null

            report.Modified = this.$firebase.firestore.FieldValue.serverTimestamp()
            this.$db.collection('IRAReport-IR').doc(key).set(report)
            .then(async()=>{
                await this.$store.dispatch('logs/createNotifs', {collection:'IRAReport-IR',documentKey:key,module: 'IRA',action: 'Updated a IRA Inherent Risk Report'})  
                this.$dialog.notify.success(`Success Updated IRA Inherent Risk Report`, {
                    position: 'bottom-right',
                    timeout: 3000
                })    
            })
        },

          displayPercentage(props){
             if(props.Dividend == undefined || props.Divisor == undefined || props.Dividend == null || props.Divisor == null || props.Dividend == 0 || props.Divisor == 0){
                return '-'
            } else {
           
        let num 
        num = (props.Dividend/props.Divisor)*100
        return num.toFixed(2)+'%'
            }
      },

      displayVulnerability(props){
        return props.Likelihood * props.ImpactScore
      },

      returnHeaders(item){
       let arr = [
         {text: 'Percentage', value: 'Percentage'},
         {text: 'Likelihood', value: 'Likelihood'},
         {text: 'Impact Score', value: 'ImpactScore'},
         {text: 'Vulnerability', value: 'Vulnerability'},
         {text: 'Risk Score', value: 'RiskScore'}
       ]
        let filter = this.IRAReportIR.filter(e=>{
          return e.Indicator === item.Indicator
        })
       let mapped = this.mapThis(filter, 'PercentageDividendName', 'Dividend', 'align')
       let mapped1 = this.mapThis(filter, 'PercentageDivisorName', 'Divisor')
       let mapped2 = this.mapThis(filter, 'AdditionalFields')

       
        arr.unshift(mapped[0])
        arr.unshift(mapped1[0])
        mapped2.forEach(e=>{
          arr.push(e)
        })
        return arr
      }, 
      mapThis(arrToMap, field, value, align){
        if(align){
           let mapped = arrToMap.map(e=>{
          return {text: e[field], align: 'start', value: value}
          })
          return mapped
        } else if(field === 'AdditionalFields'){
          let arrAdditional = []
          let filterAdditional = arrToMap.filter(e=>{
            return Object.keys(e).includes('AdditionalFields')
          })
          let mapped = filterAdditional.map(e=>{
           return e.AdditionalFields
          }) 
          for(var i = 0; i < mapped.length; i++){
          for(var j = 0; j < mapped[i].length; j++){
              arrAdditional.push(mapped[i][j]);
            }
          } 
          let mapped2 = arrAdditional.map(e=>{
            return {text: e, value: e.replace(/\s/g, '').replace(/[()]/g,'').replace(/(inPhp)/g, "")}
          })
          return this.$lodash.uniqBy(mapped2, 'text')
        } else {
          let mapped = arrToMap.map(e=>{
          return {text: e[field], value: value}
          })
          return mapped
        }
      },
      returnItems(item){
        let filter = this.IRAReportIR.filter(e=>{
          return e.Indicator === item.Indicator
        })
        filter.forEach(e=>{
          e.Rating = this.returnMetric(e)
        })
        return filter
      },
      returnRisks(){
        let filter = this.RisksIR.filter(e=>{
          return e
        })
        filter.forEach(e=>{
          e.Rating = this.returnRating(this.returnAve(e))
        })
        return filter
      },
      returnIndicators(item){
        let filter = this.RiskIndicatorsIR.filter(e=>{
          return e.Risks === item.Risks
        })
        filter.forEach(e=>{
            e.Average = this.averagePerIndicator(e)
        })
        return filter
      },
      returnAve(item){
          let arr = this.returnIndicators(item)
          let arr2 = []
          arr.forEach(e=>{
              arr2.push(e.Average)
          })
          console.log(arr2)
          let result = (arr2.reduce((a, b) => a + b, 0))/arr2.length
         return result  
      },
      returnRating(returnFunction){
          let arr = []
          let rating = returnFunction
          if(rating <= 2){
            arr.push('Low')
            return 'Low'
          } else if(rating < 4.99){
            arr.push('Medium')
            return 'Medium'
          } else if(rating === 5) {
            arr.push('High')
            return 'High'
          }
      },
      returnTotalRating(){
        let i
        let numHighCount = 0
        let numMedCount = 0
        let numLowCount = 0
        let arr0 = this.returnRisks()
        let arr = arr0.map(e=>{
          return e.Rating
        })
        console.log(arr)
        for(i=0; i < arr.length; i++){
          if(arr[i] === "High"){
            numHighCount++
          } else if(arr[i] === "Medium"){
            numMedCount++
          } else if(arr[i] === "Low"){
            numLowCount++
          }
        }
        if(numLowCount === 3 && numMedCount == 1 || numLowCount == 4){
          return "LOW"
        } else if(numLowCount == 1 && numMedCount == 2 && numHighCount == 1 || numLowCount == 2 && numMedCount == 2 || numMedCount == 3 && numHighCount == 1 || numLowCount == 2 && numMedCount == 1 && numHighCount == 1 || numLowCount == 1 && numMedCount == 3 || numMedCount == 4){
          return "MEDIUM"
        } else if (numMedCount == 2 && numHighCount == 1 || numHighCount == 1 && numMedCount == 1 && numHighCount == 2 || numLowCount == 2 && numHighCount == 2 || numMedCount == 2 && numHighCount == 2 || numLowCount == 1 && numHighCount == 3 || numMedCount == 1 && numHighCount == 3 || numHighCount == 4){
          return "HIGH"
        }
      }
    }
}
</script>