<template>
    <v-container class="pa-4 py-8">
          <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
        <v-icon class="ml-2">mdi-chart-bar</v-icon> IRA Assessment Results
        
        </div>
        
        <v-row>
          <v-col>
           {{returnHeaders()}}
            <v-data-iterator
              :items="RisksIR"
              :items-per-page.sync="itemsPerPage"
              :page="page"
              :search="search"
              :sort-by="sortBy.toLowerCase()"
              :sort-desc="sortDesc"
              hide-default-footer
              >
              
              <!-- <template v-slot:header>
                <v-toolbar
                  dark
                  color="blue darken-3"
                  class="mb-1"
                >
                  <v-text-field
                    v-model="search"
                    clearable
                    flat
                    solo-inverted
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                  <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-spacer></v-spacer>
                    <v-select
                      v-model="sortBy"
                      flat
                      solo-inverted
                      hide-details
                      :items="keys"
                      prepend-inner-icon="mdi-magnify"
                      label="Sort by"
                    ></v-select>
                    <v-spacer></v-spacer>
                    <v-btn-toggle
                      v-model="sortDesc"
                      mandatory
                    >
                      <v-btn
                        large
                        depressed
                        color="blue"
                        :value="false"
                      >
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn
                        large
                        depressed
                        color="blue"
                        :value="true"
                      >
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                </v-toolbar>
              </template> -->
              
              <template v-slot:default="props">
                 <h2 class="text-center">Risks</h2>
                <v-row>
                  <v-col
                    v-for="item in props.items"
                    :key="item.name"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    >
                    <v-card>
                      <v-card-title style="font-size: 12px">
                        {{ item.Risks }}
                      </v-card-title>

                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headers"
                        :items="returnItems(item)"
                        :hide-default-footer="true"
                        :items-per-page="5"
                        class="elevation-1"
                        >
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
        <v-row>
             <v-col lg="6">
                <h2 class="text-center">Controls</h2>

  

                      <v-data-table
                        :headers="headers2"
                        :items="desserts2"
                        :hide-default-footer="true"
                        >
                      </v-data-table>

          </v-col>
            <v-col lg="6">
              <div>
                <h2 class="text-center">Residual Risk</h2>
                <h3 class="text-center">2019</h3>
                <h2 class="text-center">MEDIUM</h2>
                  <h3 class="text-center">2019</h3>
                <h2 class="text-center solid">MEDIUM</h2>
              </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data () {
      return {
           headers: [
          {
            text: 'Department',
            align: 'start',
            value: '',
            divider: true,
            sortable: false
          },
          
          
          { text: '2019', value: 'calories', divider: true, sortable: false },
          { text: '2020', value: 'fat', divider: true, sortable: false },
        ], 
         headers2: [
          {
            text: 'Controls',
            align: 'start',
            value: 'name',
            divider: true
          },
          
          
          { text: '2019', value: 'calories', divider: true },
          { text: '2020', value: 'fat', divider: true },
        ],
        desserts: [
          {
            name: 'FOREX',
            calories: 'Med',
            fat: 'Low',
          },
          {
            name: 'WUBS',
            calories: 'High',
            fat: 'Low',
          },
          {
            name: 'COWS',
            calories: 'Low',
            fat: 'Med',
          },
          {
            name: 'SUB-AGENT',
            calories: 'Low',
            fat: 'Low',
          },
        ],
         desserts2: [
          {
            name: 'PEOPLE',
            calories: 'Med',
            fat: 'Low',
          },
          {
            name: 'PROCESS & TECHNOLOGY',
            calories: 'High',
            fat: 'Low',
          }
        ],
  
        itemsPerPageArray: [4, 8, 12],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 4,
        sortBy: 'name',
        keys: [
          'Name',
          'Calories',
          'Fat',
          'Carbs',
          'Protein',
          'Sodium',
          'Calcium',
          'Iron',
        ],
        items: [
          {
            name: 'Customer/Entity Risk',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            sodium: 87,
            calcium: '14%',
            iron: '1%',
          },
          {
            name: 'Products/Services & Channel Risk',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            sodium: 129,
            calcium: '8%',
            iron: '1%',
          },
          {
            name: 'Country/Jurisdiction',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            sodium: 337,
            calcium: '6%',
            iron: '7%',
          },
          {
            name: 'Employee',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            sodium: 413,
            calcium: '3%',
            iron: '8%',
          },
        ],
        items2: [
          {
            name: 'Controls',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            sodium: 87,
            calcium: '14%',
            iron: '1%',
          }
        ],
      }
    },
     firestore(){
      return {
        RiskIndicatorsIR: this.$db.collection('RiskIndicators-IR'),
        IRAReportIR: this.$db.collection('IRAReport-IR').where("IRACoverageId", "==", this.$route.params.id),
        IRAReportCC: this.$db.collection('IRAReport-CC').where("IRACoverageId", "==", this.$route.params.id),
        IRACoverage: this.$db.collection('IRACoverage').doc(this.$route.params.id),
        RisksIR: this.$db.collection('Risks-IR'),
        DepartmentIR: this.$db.collection('Department-IR')
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
      // returnHeaders(){
      //   this.IRA
      // },
      returnItems(item){
       let filter = this.IRAReportIR.filter(e=>{
          return e.Risks == item.Risks
        }) 
        console.log(filter)       
      },
      returnHeaders(){
        if(this.IRACoverage.length == 0){
          return []
        }
       let IRACoverageYear = this.IRACoverage.startDate
      
        console.log(IRACoverageYear.slice(0,-6))
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    }
}
</script>
<style scoped>
h3.solid {border-style: solid !important;}
</style>