<template>
    <v-container class="pa-4 py-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
         <router-link :to="{name: 'ViewAllIRACoverage'}"><v-icon>mdi mdi-arrow-left</v-icon></router-link><v-icon class="ml-2">mdi-format-list-text</v-icon> View All IRA's

            <span class="grey--text text--lighten-1">(
              <span v-if="returnORMPFilter">{{deptFilter}}</span> 
              <span v-else>{{$store.getters['useraccount/isAuthenticated'].department}}</span>)
            </span>
          <div class="float-right row mr-2">
            <!-- <v-select
                :items="returnDept"
                v-model="deptFilter"
                label="Select Department"
                class="mr-4 col"
                hide-details
                outlined
                style="width: 200px;"
                v-show="returnORMPFilter"
                dense
                >
            </v-select>  -->
          </div>
        </div>

        <v-card>
            <v-toolbar
                color="gray"
                light
                flat
                >
                <v-tabs
                v-model="tab"
                align-with-title
                >
                <v-tabs-slider color="gray"></v-tabs-slider>

                <v-tab>
                    Inherent Risk
                </v-tab>
                <v-tab>
                    Controls
                </v-tab>
                <v-tab>
                    Residual Risk Rating
                </v-tab>
                </v-tabs>
    
            </v-toolbar>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                   <InherentRiskTable :id="this.$route.params.id" @update="handleUpdateInherentRisk" />
                    <ControlTable @update="handleUpdateControlTable" v-if="tab == 2"/>
                </v-tab-item>
                  
                <v-tab-item>
                    <ControlTable @update="handleUpdateControlTable" />
                </v-tab-item>
                
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <h1>RESIDUAL RISK</h1>
                                <div class="mt-8 mb-4">
                                  <span :class="returnResidualClass()">{{returnFinalRating()}}</span>
                                </div>
                                
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-card-text>
                    </v-card>
            </v-tab-item>
            <v-tab-item>
              <IRAAssessmentResult />
            </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
import InherentRiskTable from '../components/InherentRiskTable'
import ControlTable from '../components/ControlTable'
import IRAAssessmentResult from '../components/IRAAssessmentResult'
export default {
  components:{
    InherentRiskTable,
    ControlTable, 
    IRAAssessmentResult
  },
    data () {
      return {
        inherentRiskRating: '',
        controlRiskRating: '',
        deptFilter: 'SYSDEV',
        tab: null,
      desserts: [],
      }
    },

    firestore(){
      return {
        RiskIndicatorsIR: this.$db.collection('RiskIndicators-IR'),
        IRAReportIR: this.$db.collection('IRAReport-IR').where("IRACoverageId", "==", this.$route.params.id),
        RisksIR: this.$db.collection('Risks-IR')
      }
    },

    
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

       returnIRAs(){
        let before = this.ORMPs
        let self = this

        let user = self.$store.getters['useraccount/isAuthenticated']
        
       
            return self.$lodash.uniqBy(before.filter(a=>{
                return a.Department == user.department
            }), e=>{
                return e.RiskName.RiskName
            })          
        
      },

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },



    methods: {
       returnResidualClass(){
        if(this.returnFinalRating() == "LOW RISK"){
          return 'residualRiskLow'
        } else if(this.returnFinalRating() == "MEDIUM RISK"){
          return 'residualRiskMedium'
        } else if(this.returnFinalRating() == "HIGH RISK"){
          return 'residualRiskHigh'
        }
      },

       handleUpdateInherentRisk(info) {
          this.inherentRiskRating = info 
      },

      handleUpdateControlTable(info){
        this.controlRiskRating = info
      },

      returnFinalRating(){
        let inherent = this.inherentRiskRating
        let control = this.controlRiskRating
        if(inherent == "LOW" && control == "STRONG" || inherent == "LOW" && control == "ACCEPTABLE" || inherent == "MEDIUM" && control == "STRONG"){
          return "LOW RISK"
        } else if(inherent == "LOW" && control == "WEAK" || inherent == "MEDIUM" && control == "ACCEPTABLE" || inherent == "HIGH" && control == "STRONG"){
          return "MEDIUM RISK"
        } else if(inherent == "MEDIUM" && control == "WEAK" || inherent == "HIGH" && control == "WEAK" || inherent == "HIGH" && control == "ACCEPTABLE"){
          return "HIGH RISK"
        }
      },
        
      forField(field) {
        console.log(field)
        if([field].length == 0){
          return []
        }
        let hello = []
        field.forEach(e=> {
         e.headers.forEach(e1=> {
           hello.push(e1)
         })
        })
        console.log(hello)
        return hello
      },
      forFieldItems(field){
        if([field].length == 0){
          return []
        }
        let hello = []
        field.forEach(e=> {
          hello.push(e.items)
        })
        console.log(hello)
        return hello
      },
      returnORMPFilter(){
          if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
              return false
          }
          return true
      },
      returnDept(){
          let db = this.Departments.map(a=>{
              return {
                  text: a.departmentName,
                  value: a.departmentName
              }
          })
          return db
      },
  
      addRowCustomerEntityRisk(){
        let arr = []
        let filter = this.RisksIndicatorsIR.filter(e =>{
          return e.Indicator == this.selectedIndicator
        })
       let mapped = filter.map(e=>{
         return {
           Indicator: e.Indicator,
           Rationale: e.Rationale,
         }
        })
        
        let mappedItem = mapped[0]

        let headers = [
          {text: 'Indicator', value: 'Indicator'},
          {text: 'Rationale', value: 'Rationale'}
        ]
        arr.push({items: mappedItem, headers: headers})
        // console.log(arr)
         this.tables.push(arr)

      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

 

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    }
}
</script>
<style scoped>
  a:link {
  text-decoration: none;
  }
  .residualRiskLow{
    background-color: #00C851;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    padding-top:0.3em;
    padding-bottom:0.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: white;
  }
    .residualRiskMedium{
    background-color: #ffbb33;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    padding-top:0.3em;
    padding-bottom:0.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: white;
  }
    .residualRiskHigh{
    background-color: #ff4444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 25px;
    padding-top:0.3em;
    padding-bottom:0.3em;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: white;
  }

</style>